/* -------------------------------- *\
    Figure
\* -------------------------------- */
.fluid-image {
	width: 100%;
	max-width: 100%;
	height: auto;
}

/* -------------------------------- *\
    Video
\* -------------------------------- */
.video-container {
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 56.25%;
	page-break-inside: avoid;

	& iframe {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		outline: 0;
	}
}

/* Figure */
.figure {
	margin: 0 0 1.6rem;
}
.figure__caption {
	margin-top: .4rem;
	color: var(--gray-1);
}

/* Object-fit Polyfill */
.objectfit {
	object-fit: cover;
	font-family: "object-fit: cover;";

	&[data-fit="contain"] {
		object-fit: contain;
		font-family: "object-fit: contain;";
	}
}

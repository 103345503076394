/* -------------------------------- *\
   8. BREADCRUMB
\* -------------------------------- */
.breadcrumb {
	padding: 1.2rem 0 2.4rem;

	& ol {
		list-style: none;
		display: flex;
		width: 100%;
		margin: 0;
		padding: 0;
	}
	& li {
		white-space: nowrap;
	}
	& li:not(:last-of-type)::after {
		display: inline-block;
		padding: 0 .6rem;
		color: var(--gray-3);
		font-family: var(--iconfont);
		font-size: 1.2rem;
		content: "\e903";
	}
	& a {
		color: inherit;
	}
}

@media screen and (width <= 60em) {
	.breadcrumb {
		& ol {
			overflow-x: auto;
			word-break: keep-all;
			white-space: nowrap;
		}
	}
}

@media (width > 60em) {
	.breadcrumb {
		padding: 1.6rem 0 3rem;

		& li + li::before {
			padding: 0 .9rem;
		}
	}
}

/* -------------------------------- *\
    13. BUTTONS
\* -------------------------------- */
.btn {
	display: block;
	position: relative;
	width: 88.2%;
	margin: 0 auto 1.6rem;
	padding: 1.3rem 2.4rem 1.3rem 1.6rem;
	border: .2rem solid;
	border-radius: 5rem;
	background-color: transparent;
	color: var(--color-text);
	font-family: inherit;
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	text-decoration: none !important;
	appearance: none;
	transition: all .2s ease;

	&::before {
		content: "\e902";
		position: absolute;
		right: 2.2rem;
		top: 50%;
		transform: translateY(-50%);
		font-family: var(--iconfont);
		font-size: 2.4rem;
		font-weight: normal;
		transition: all .2s ease;
	}
	&:not(:disabled) {
		cursor: pointer;
	}
	&[aria-disabled="true"] {
		opacity: .5;
	}
	&:hover {
		border-color: var(--gray-6);
		color: var(--color-text);
	}
	&:focus {
		transition: outline 0s;
		outline: 2px auto Highlight;
		outline: 5px auto -webkit-focus-ring-color;
	}
}
.btn--external::before {
	content: "\e904";
	font-size: 1em;
}
.btn__icon {
	margin-left: .3rem;
	font-size: 1.2em;
}

@media print, (width > 60em) {
	.btn {
		width: 100%;
		max-width: 384px;
		padding: 1.35rem 2.4rem 1.35rem 1.6rem;
		font-size: 1.8rem;
	}
}

/* 13-1: ボタン - 高（Primary） */
.btn--primary {
	border-color: transparent !important;
	background-color: var(--color-primary);
	color: #fff;

	&:hover {
		background-color: var(--color-primary-light);
		color: #fff;
	}
}

/* 13-2: ボタン - 低（Secondary） */
.btn--secondary {
	border-color: var(--gray-2);
	background-color: #fff;
	color: var(--color-text);

	&::before {
		color: var(--color-secondary);
	}
	&:hover {
		color: var(--gray-2);
	}
	&:hover::before {
		color: #bacff0;
	}
}

/* 戻るボタン */
.btn--back {
	padding-right: 1.6rem;
	padding-left: 2.4rem;

	&::before {
		right: auto;
		left: 2.4rem;
		content: "\e923";
	}
}

@media (width > 60em) {
	.btn--back {
		&:hover::before {
			right: auto !important;
		}
	}
}

/* 単色ボタン */
.sub-btn {
	display: flex;
	position: relative;
	height: calc(100% - 16px);
	margin: 8px 0;
	padding: 0;
	border: none;
	background-color: transparent;
	color: inherit;
	font-family: inherit;
	font-size: 1.7rem;
	font-weight: bold;
	line-height: 1.4;
	text-align: center;
	text-decoration: none !important;
	appearance: none;
	transition: all .2s ease;
}
.sub-btn__text {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 5.6rem;
	height: 100%;
	margin: 0;
	padding: .8rem 1.6rem;
	color: inherit;
	font-size: inherit;
}
.sub-btn__icon {
	margin-right: .8rem;
}
.sub-btn--primary {
	background: var(--color-primary);
	color: #fff;
}
.sub-btn--secondary {
	border: .2rem solid var(--gray-2);
	background-color: #fff;
	color: var(--color-text);

	&:hover {
		color: var(--gray-2);
	}
}
.sub-btn--tertiary {
	background: var(--gray-4);
	color: var(--color-primary);
}

@media screen and (width <= 60em) {
	.sub-btn__icon {
		width: 3.2rem;
	}
}

@media (width > 60em) {
	.sub-btn {
		font-size: 2rem;
	}
	.sub-btn__text {
		min-height: 8.4rem;
		padding: 1.6rem;
	}
	.sub-btn--primary {
		&:hover {
			background: var(--color-primary-light);
		}
	}
}

/* 別タブボタン */
.btn--blanklink{

	&::before {
		content: "\e904" !important;
	}
}
/* -------------------------------- *\
    18. INPUT FORM + BUTTON
\* -------------------------------- */
.form-input,
.form-select {
	display: block;
	width: 100%;
	max-width: 100%;
	margin: .4rem 0;
	border-radius: .6rem;
	background-color: var(--gray-5);
	border: .2rem solid var(--gray-3);
	color: inherit;
	font-size: 1.6rem;
	appearance: none;
	outline: 0;
	transition: all .1s;
}
.form-input {
	height: 4.2rem;
	padding: 0 1.2rem;
	line-height: 1;

	&::-ms-clear,
	&::-ms-reveal {
		/* IE10以上用 */
		height: 20px;
		margin-right: -6px;
		color: rgb(0 0 0 / .5);
	}
}
textarea.form-input {
	height: auto;
	min-height: 8em;
	padding: 1.2rem;
	line-height: 1.4;
}
.form-select {
	height: 4.2rem;
	padding: 0 1.2rem;
	text-overflow: ellipsis;

	&:not([multiple]) {
		padding-right: 3.2rem;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj48cGF0aCBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMzMzO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS13aWR0aDoycHg7IiBkPSJNMTAsNCw2LDgsMiw0Ii8+PC9zdmc+");
		background-repeat: no-repeat;
		background-position: right 1.2rem center;
		background-size: 1.2rem;
		line-height: normal;
		vertical-align: middle;
	}
	&[multiple] {
		height: auto;
		padding: .8rem 1.2rem;
	}
	&[multiple] option,
	&[size] option {
		padding: .1rem .2rem;
	}
}
.form-select::-ms-expand {
	display: none;
}
.form-input:focus,
.form-select:focus {
	border-color: var(--color-primary);
}
[class*="form-"]:disabled,
[class*="form-"][readonly] {
	background-color: #f5f5f7;
	color: var(--gray-1);
}
[class*="form-"]::placeholder {
	color: var(--gray-2);
}
[class*="form-"]:-ms-input-placeholder {
	color: var(--gray-2);
}

/* チェックボックス・ラジオボタン */
/*
<label class="form-check" for="check1">
  <input class="form-check__input" type="checkbox" name="check" id="check1" value="選択する">
  <span class="form-check__label">選択する</span>
</label>
*/
.form-check {
	display: inline-block;
	margin-right: 1.2rem;
	padding-left: 1.92857em;
}
.form-check__input {
	appearance: none;
	width: 1.5em;
	height: 1.5em;
	border: .2rem solid var(--gray-2);
	background-color: #fff;
	background-position: center center;
	background-size: contain;
	font-size: inherit;
	outline: none;
	vertical-align: top;

	&[type="checkbox"] {
		border-radius: .4rem;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggc3R5bGU9ImZpbGw6I2ZmZjsiIGQ9Ik00LDE1LjIsNy4yLDEybDYuNCw2LjRMMjQuOCw3LjIsMjgsMTAuNCwxMy42LDI0LjhaIi8+PC9zdmc+);
	}
	&[type="radio"] {
		border-radius: 50%;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PGNpcmNsZSBzdHlsZT0iZmlsbDojZmZmOyIgY3g9IjE2IiBjeT0iMTYiIHI9IjciLz48L3N2Zz4=);
	}
	&:checked {
		border-color: var(--color-secondary);
		background-color: var(--color-secondary);
	}
	&:focus {
		border-color: var(--color-secondary);
	}

	@nest .form-check & {
		float: left;
		margin-left: -1.92857em;
	}
}
.form-check__label {
	display: inline-block;
	line-height: 1;
}
.form-check__input:disabled,
.form-check__input:disabled ~ .form-check__label {
	opacity: .5;
}
.form-check__input:disabled:checked {
	border-color: var(--gray-2);
	background-color: var(--gray-2);
}

/* -------------------------------- *\
    スタイルバリエーション
\* -------------------------------- */
/* 幅 */
.form-width--name {
	max-width: 10em;
}
.form-width--zip,
.form-width--tel {
	width: 5em;
}
.form-width--large {
	max-width: 600px;
}
.form-width--medium {
	max-width: 250px;
}
.form-width--small {
	max-width: 160px;
}

/* バリデーションカラー */
.form-input.is-invalid,
.form-select.is-invalid,
.form-check__input.is-invalid,
.is-invalid .form-check__input {
	border-color: var(--color-error);
}
.invalid-message {
	margin-top: .8rem;
	color: var(--color-error);
}

/* -------------------------------- *\
    フォームレイアウト
\* -------------------------------- */
.form-row {
	margin-bottom: 1.6rem;
	page-break-inside: avoid;
}
.form-label {
	margin-bottom: .5rem;
	font-weight: bold;
}
.form-input-group {
	display: flex;
	align-items: center;

	& > * + * {
		margin-left: .9rem;
	}
}
.form-btn {
	display: inline-flex;
	justify-content: center;
	min-width: 7rem;
	height: 4.2rem;
	padding: .9rem;
	border-radius: .6rem;
	border: none;
	background: var(--color-primary);
	color: #fff;
	text-align: center;
	white-space: nowrap;
	appearance: none;
	cursor: pointer;
	transition: all .15s ease;

	&:hover {
		background: var(--color-primary-light);
	}
}
.form-required-label {
	color: var(--color-error);
}
.form-desc {
	margin-bottom: .8rem;
}
.form-help {
	margin-top: .8rem;
	color: var(--gray-1);
	font-size: calc(1em - .2rem);
}

@media print, (width > 45em) {
	.form-row--horizontal {
		display: flex;

		& .form-label {
			flex: 1 0 200px;
			max-width: 200px;
			padding-top: 1.2rem;
		}
		& .form-controls {
			flex: 1;
			padding-left: 2.4rem;
		}
		& .form-controls--buffer {
			padding-top: 1.2rem;
		}
	}
	.confirm {
		& .form-row--horizontal {
			& .form-label,
			& .form-controls {
				padding-top: 0;
			}
		}
	}
}

@media (width > 60em) {
	.form-btn {
		min-width: 9.6rem;
	}
}

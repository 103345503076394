/* ヒーロー画像 */
.bo-index-hero {
	overflow: hidden;
}
.bo-index-hero__slide {
	position: relative;

	& img {
		width: 100%;
	}
}
.bo-index-hero__slide_link {
	display: block;
	transition: opacity .2s;

	&:hover {
		opacity: .75;
	}
}
.owl-dots {
	position: relative !important;
	text-align: center !important;
	margin: 24px 0 0 0!important;
}
@media (width > 45em) {
	.owl-dots {
		position: absolute !important;
		margin: 0!important;
	}
}

/* TOPセクション */
.bo-index-bg--white,
.bo-index-bg--silver {
	padding: 60px 0 40px;
}
.bo-index-bg--silver {
	background: var(--gray-5);
}

@media (width > 60em) {
	.bo-index-bg--white{
		padding: 60px 0 70px;
	}
	.bo-index-bg--silver {
		padding: 60px 0 70px;
	}
	.bo-index-bg--white,
	.bo-index-bg--silver{
		& .h1 {
			margin-bottom: 40px;
		}
	}
}

/* カテゴリ導入画像 */
.bo-index-category {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.bo-index-category__item {
	position: relative;
	z-index: 0;
	flex: 1 0 49%;
	max-width: 49%;
}
.bo-index-category__hover{
	background: #fff;

	& a:hover {
		opacity: .75;
	}
	& a:hover img {
		opacity: 1;
	}
}
.bo-index-category__item--full {
	flex: 1 0 100%;
	max-width: 100%;
}
.bo-index-category__item:nth-of-type(n+2) {
	margin-top: 24px;
}
.bo-index-category__item__corporate {
	position: relative;
	z-index: 0;
	flex: 1 0 49%;
	max-width: 49%;
}
.bo-index-category__item--3 {
	flex: 1 0 32%;
	max-width: 32%;
	margin-top: 0 !important;
}
.bo-index-category__item__corporate:nth-of-type(n+3) {
	margin-top: 24px !important;
}
.bo-index-category__text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	font-size: 1.8rem;
	font-weight: bold;
	text-align: center;
}
.bo-index-category__heading {
	display: inline-block;
	margin-bottom: .8rem !important;
	padding: .8rem 1.6rem;

	& .h2 {
		margin-bottom: 0;
		font-size: 1.6rem;
	}
	& .h2[data-en]::before {
		content: attr(data-en);
		display: block;
		font-size: .4em;
	}
	& .h2[data-en]::after {
		display: none;
	}
}
.bk_gl{
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(22, 51, 87, .4);
	width: 240px;
	max-width: 80%;
	min-height: 50px;
	padding-right: 0;
	padding-left: 0;
	line-height: 1.2;
}
.bo-index-category__heading__message{
	margin-bottom: 0 !important;
	padding: .2rem .4rem;
}

@media (width <= 35em) {
	.bo-index-category__item--3 {
		flex: 1 0 49%;
		max-width: 49%;
		margin-bottom: 10px;
	}
}
@media (width > 60em) {
	.bo-index-category__heading__message{
		margin-bottom: .8rem !important;
		padding: .8rem 1.6rem;
	}
	.bk_gl{
		width: 290px;
		min-height: 84px;
	}
	.bo-index-category__item__csr {
		flex: 1 0 33%;
		max-width: 33%;
	}
}

.bo-index-category__image {
	overflow: hidden;
	height: 0;

	& img {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.bo-index-category__image--main {
	padding-top: 56.25%;
}
.bo-index-category__image--sub {
	padding-top: 57.627%;
}

@media (width <= 45em) {
	.bo-index-category__text.message {
		top: auto;
		bottom: 0;
		transform: none;
		background: rgba(255, 255, 255, .5);
	}
}

@media print, (width > 45em) {
	.bo-index-category__heading {

		& .h2 {
			font-size: 3rem !important;
		}
		& .h2[data-en]::before {
			font-size: .6em;
		}
	}
	.bo-index-category__image {
		padding-top: 57.627%;
	}
	.bo-index-category__image--main {
		padding-top: 30.23%;
	}
	.bo-index-category__text.message {
		top: 50%;
		padding-left: 40%;
	}
}

@media (width > 60em) {
	.bo-index-category__heading {

	}
	.bo-index-category__text {
		font-size: 2.8rem;
	}
}

/* 主要サービス */
.bo-index-service {
	& ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	& img {
		border-radius: .5rem;
	}
	& dt {
		margin-bottom: .4rem;
		font-size: 1.6rem;
		font-weight: bold;
	}
}

@media (width <= 60em) {
	.bo-index-service {
		& ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -10px;
			padding: 0;
		}
		& li {
			flex: 1 0 50%;
			max-width: 50%;
			margin-bottom: 8px;
			padding: 0 10px;
		}
	}
	.bo-index-service-domestic,
	.bo-index-service-oversea {
		padding: 1.6rem 2rem;
		& h3 {
			border-bottom: .2rem solid #ebebeb;
			padding-bottom: .8rem;
		}
	}
}

@media print, (width > 60em) {
	.bo-index-service {
		display: flex;
		justify-content: space-between;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 63.5833%;
			border-left: 1px solid var(--gray-4);
		}
	}
	.bo-index-service-domestic {
		flex: 1 0 60.25%;
		max-width: 60.25%;

		& ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -10px;
			padding: 0;
		}
		& li {
			flex: 1 0 33.3333%;
			max-width: 33.3333%;
			padding: 0 10px;
			margin-bottom: 24px;
		}
	}
	.bo-index-service-oversea {
		flex: 1 0 33.16%;
		max-width: 33.16%;

		& li {
			margin-bottom: 24px;
		}
		& dl {
			display: flex;
		}
		& dt {
			flex: 1 0 42.4242%;
			max-width: 42.4242%;
		}
		& dd {
			flex: 1 0 57.3232%;
			max-width: 57.3232%;
			margin-bottom: 16px;
		}
	}
}

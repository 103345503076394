.layout-content {
	overflow-x: hidden;
}
.layout-header {
	height: 112px;
}

@media (width <= 60em) {
	.layout-header {
		height: 62px;
	}
	.layout-content {
		transition: z-index 0s .3s;
	}

	/* パネル展開時の画面表示 */
	.nav-opened {
		& body {
			position: fixed;
			width: 100%;
		}
		& .layout-content {
			z-index: -100;
			pointer-events: none;
			transition: z-index 0s;
		}
		& .layout-content::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: rgba(0, 0, 0, .5);
		}
	}
}

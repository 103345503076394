/* -------------------------------- *\
   ACCORDION
\* -------------------------------- */
[data-accordion-header] {
	display: block;
	position: relative;
	width: 100%;
	padding: 1.6rem 4rem 1.6rem 1.2rem;
	border: 0;
	background: var(--gray-5);
	font-family: inherit;
	font-size: inherit;
	text-align: inherit;
	text-decoration: none;
	appearance: none;
	color: var(--color-text);

	&::after {
		content: "\e917";
		position: absolute;
		top: 50%;
		right: 1.2rem;
		transform: translateY(-50%);
		color: var(--color-secondary);
		font-family: var(--iconfont);
	}
	&[aria-expanded="true"]::after {
		content: "\e912";
	}
}
[data-accordion-pane] {
	display: block !important;
	overflow: hidden;
	margin-bottom: .4rem;
	padding-right: 1.2rem;
	padding-left: 1.2rem;
	line-height: 0;
	opacity: 0;
	transition: all .2s .1s ease-out, opacity .2s;

	&.is-opened {
		opacity: 1;
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
		line-height: 1.571428;
		transition: all .3s ease-out, opacity .2s .2s;
	}
}

@media print {
	[data-accordion-header]::after {
		display: none;
	}
	[data-accordion-pane] {
		opacity: 1;
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
		line-height: 1.571428;
	}
}

@media (width > 45em) {
	[data-accordion-header] {
		padding: 1.6rem 4rem 1.6rem 1.6rem;
		font-size: 1.6rem;

		&::after {
			right: 1.6rem;
		}
	}
	[data-accordion-pane] {
		padding-right: 1.6rem;
		padding-left: 1.6rem;

		&.is-opened {
			padding-top: 1.6rem;
			padding-bottom: 1.6rem;
		}
	}
}

/* FAQ */
.faq-list {
	list-style: none;
	padding: 0;

	& > li {
		border: 1px solid var(--gray-4);
	}
}
.faq-list__header {
	& button {
		padding-left: 4rem;
	}
	& button::before {
		content: "Q";
		position: absolute;
		top: 1.2rem;
		left: 1.2rem;
		color: var(--color-secondary);
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 1;
	}
}
.faq-list__pane {
	position: relative;
	margin-bottom: 0;
	padding-left: 4rem;

	&::before {
		content: "A";
		position: absolute;
		top: 1.2rem;
		left: 1.2rem;
		color: var(--gray-1);
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 1;
	}
	&.is-opened {
		border-top: 1px solid var(--gray-4);
	}
}

@media (width > 45em) {
	.faq-list__header {
		& button {
			padding: 1.6rem 4.6rem;
		}
		& button::before {
			top: 1.5rem;
			left: 1.4rem;
		}
	}
	.faq-list__pane {
		padding-left: 4.6rem;

		&::before {
			top: 1.5rem;
			left: 1.4rem;
		}
	}
}

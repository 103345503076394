/* -------------------------------- *\
    main
\* -------------------------------- */
.content {
	padding-bottom: 40px;
}

@media (width > 60em) {
	.content {
		padding-bottom: 60px;
	}
}

/* -------------------------------- *\
    コンテンツ共通
\* -------------------------------- */
.wrapper {
	position: relative;
	width: 90.4%;
	max-width: 1200px;
	margin: 0 auto;
}
.section {
	margin-bottom: 50px;
}
.section .section {
	margin-bottom: 40px;
}
.section .section .section {
	margin-bottom: 30px;
}
.section .section .section .section {
	margin-bottom: 24px;
}

@media (width > 60em) {
	.section {
		margin-bottom: 60px;
	}
	.section .section {
		margin-bottom: 50px;
	}
	.section .section .section {
		margin-bottom: 40px;
	}
	.section .section .section .section {
		margin-bottom: 30px;
	}
	.section .section .section .section .section {
		margin-bottom: 24px;
	}
}

/* -------------------------------- *\
    5. DIVIDER & BACKGROUND
\* -------------------------------- */
/* 5-1: 境界線 */
.divider {
	height: 0;
	margin: 2.4rem 0;
	border: none;
	border-top: 1px solid var(--gray-4);
}

/* 5-2: 強調エリア */
/* 5-3: 強調枠 */
.frame {
	padding: 16px 20px 24px;
	border: 1px solid var(--gray-4);
	background: #fff;

	& > :last-child {
		margin-bottom: 0;
	}
}
.frame--bg {
	background: var(--gray-5);
}

@media (width > 60em) {
	.frame {
		padding: 24px 24px 30px;
	}
}

/* -------------------------------- *\
    12. LIST
\* -------------------------------- */
/* 12-3: 注釈リスト */
.note-list {
	color: var(--gray-1);
	font-size: 1.2rem;
	line-height: 1.6;

	& > li {
		margin-bottom: .4rem;
	}
}
.note-list--icon {
	list-style: none;
	padding-left: 0;

	& > li {
		padding-left: 1em;
		text-indent: -1em;
	}
	& > li::before {
		content: "※";
	}
}

@media (width > 60em) {
	.note-list {
		font-size: 1.3rem;
	}
}

/* 14-1: メニューリスト */
/* 14-2: 説明付きメニューリスト */
.menu-list {
	list-style: none;
	border-top: 1px solid var(--gray-4);
	padding: 0;

	& li {
		margin-bottom: 0;
		border-bottom: 1px solid var(--gray-4);
	}
	& a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: 1.6rem 4.8%;
		color: var(--color-text);
		font-size: 1.6rem;
		text-decoration: none;
	}
	& a::after {
		content: "\e902";
		margin-left: 1.2rem;
		color: var(--color-secondary);
		font-family: var(--iconfont);
		font-size: 2.4rem;
		line-height: 1;
	}
	& small {
		display: block;
		margin-top: .2rem;
		color: var(--gray-1);
		font-size: 1.2rem;
	}
}

@media (width > 60em) {
	.menu-list {
		& a {
			padding-left: 0;
			padding-right: 0;
		}
		& a::after {
			transition: all .3s ease;
		}
		& a:hover::after {
			transform: translateX(.4rem);
		}
	}
}

/* タブスタイルメニューリスト（分割設定） */
.tablike-menu-list {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -.3rem 30px;
	padding: 0;

	& > li {
		display: flex;
		flex: 1 0 50%;
		max-width: 50%;
		margin: .4rem 0;
		padding: 0 .3rem;
	}
	&.is-column2 > li {
		flex-basis: 100%;
		max-width: 100%;
	}
}

@media print, (width > 45em) {
	.tablike-menu-list {
		& > li {
			flex: 1;
			max-width: none;
		}
		&.is-column2 > li {
			flex: 1 0 50%;
			max-width: 50%;
		}
		&.is-column3 > li {
			flex: 1 0 33.3333%;
			max-width: 33.3333%;
		}
		&.is-column4 > li {
			flex: 1 0 25%;
			max-width: 25%;
		}
		&.is-column5 > li {
			flex: 1 0 20%;
			max-width: 20%;
		}
	}
}

@media print, (width > 60em) {
	.tablike-menu-list {
		margin: 0 -.6rem 40px;

		& > li {
			margin: .6rem 0;
			padding: 0 .6rem;
		}
	}
	.tablike-menu-list.is-inline {
		justify-content: flex-start;
	}
	.tablike-menu-list.is-inline > li {
		flex: 1 1 auto;
		max-width: none;
	}
}

/* タブスタイルメニューリスト（ボタンスタイル） */
.tablike-menu-list a {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 5rem;
	padding: 1.2rem;
	line-height: 1.333;
	text-decoration: none;
	text-align: center;
	border: 1px solid var(--gray-4);
	background: #fff;
	color: inherit;

	&.is-current,
	&[aria-current] {
		background: var(--color-primary) !important;
		color: #fff !important;
	}
}

@media print, (width > 60em) {
	.tablike-menu-list a {
		min-height: 7rem;
		padding-right: 1.6rem;
		font-size: 1.6rem;

		&:hover {
			text-decoration: underline;
			color: var(--color-primary);
		}
	}
}

/* -------------------------------- *\
    15. LINKS
\* -------------------------------- */
/* 15-4: リンクリスト */
.link-list {
	list-style: none;
	margin-bottom: 1.8rem;
	padding-left: 0;

	& > li {
		position: relative;
		margin-bottom: .6rem;
		padding-left: 1.5rem;
	}
	& a {
		display: inline-block;
		color: inherit;
	}
	& a::before {
		content: "\e903";
		position: absolute;
		top: .2em;
		left: 0;
		color: var(--color-secondary);
		font-display: block;
		font-family: var(--iconfont);
		font-size: 1.2rem;
		font-weight: normal;
		text-decoration: none;
	}
}
.link-list--line {
	padding-top: .6rem;
	border-top: 1px solid var(--gray-4);

	& > li {
		padding-bottom: .6rem;
		border-bottom: 1px solid var(--gray-4);
	}
}

@media (width > 60em) {
	.link-list {
		& > li {
			margin-bottom: 1.2rem;
		}
		& a:hover {
			color: var(--color-secondary);
		}
		& a:hover::before {
			color: var(--color-primary);
		}
	}
	.link-list--line {
		padding-top: 1.2rem;

		& > li {
			padding-bottom: 1.2rem;
		}
	}
}

/* 15-5: 外部サイトリンク */
/* 15-6: PDFリンク */
.link-list a[target="_blank"]::after,
.link-list a[href*=".pdf"]::after {
	display: inline-block;
	margin-left: .3rem;
	color: var(--gray-2);
	font-display: block;
	font-family: var(--iconfont);
	font-weight: normal;
	text-decoration: none;
	line-height: 1;
}
.link-list a[target="_blank"]::after {
	content: "\e904";
}
.link-list a[href*=".pdf"]::after {
	content: "\e915";
	font-size: 1.6rem;
	vertical-align: -.15em;
}
.link-list a[target="_blank"]:hover::after,
.link-list a[href*=".pdf"]:hover::after {
	color: var(--color-primary);
}

/* ページ内リンクリスト */
.inpage-link-list {
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;

	& > li {
		display: flex;
		margin: 0 5rem 1rem 0;
	}
	& a {
		display: block;
		position: relative;
		padding: .4em calc(1em + .6rem) .4em 0;
		color: inherit;
		line-height: 1.333;
		text-decoration: none;
	}
	& a::before {
		content: "\e921";
		position: absolute;
		right: 0;
		top: .5em;
		color: var(--color-secondary);
		font-family: var(--iconfont);
		line-height: 1;
		text-decoration: none;
	}
	& a::after {
		content: '';
		position: absolute;
		right: -1.5em;
		top: 0;
		bottom: 0;
		width: 3px;
		background-color: var(--gray-5);
		margin: auto;
	}
}

@media print, (width > 45em) {
	.inpage-link-list {

		& a {
			font-size: 1.6rem;
		}
	}
}

@media print, (width > 60em) {
	.inpage-link-list {
		& a:hover {
			text-decoration: underline;
		}
	}
}

/* スタイルなしリスト */
.unstyled-list {
	list-style: none;
	padding-left: 0;
}

/* -------------------------------- *\
    16. DATA TABLE
\* -------------------------------- */
.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1.2rem;
	border-top: 1px solid var(--gray-4);

	& th,
	& td {
		padding-top: .8rem;
		padding-bottom: .8rem;
		border-bottom: 1px solid var(--gray-4);
		vertical-align: top;
	}
	& th {
		text-align: inherit;
	}
	& td {
		padding-left: 1.2rem;
	}
}

@media (width > 60em) {
	.table td {
		padding-left: 2.4rem;
	}
}

/* レスポンシブ */
@media screen and (width <= 45em) {
	.table-scroller {
		display: block;
		width: 100%;
		overflow-x: auto;
		white-space: nowrap;

		& table {
			min-width: 720px;
		}
	}
	.table--block {
		display: block;

		& caption,
		& thead,
		& tbody,
		& tr,
		& th,
		& td {
			display: block;
			width: 100% !important;
		}
		& tr {
			border-bottom: 1px solid var(--gray-4);
		}
		& th {
			padding-bottom: 0;
			border-bottom: none;
		}
		& td {
			padding-top: .4rem;
			padding-left: 0;
			border-bottom: none;
		}
	}
}

/* テーブルテーマ */
.table--theme1 {
	& caption {
		padding: .8rem 1.2rem;
		background: var(--gray-4);
		font-size: 1.6rem;
		text-align: left;
	}
	& th,
	& td {
		padding-right: 1.2rem;
		padding-left: 1.2rem;
	}
	& th {
		background: var(--gray-5);
	}
}
.table--theme2 {
	border-top: 1px solid var(--gray-3);
	border-right: 1px solid var(--gray-3);

	& caption {
		padding: .8rem 1.2rem;
		background: var(--color-primary);
		color: #fff;
		font-size: 1.6rem;
		text-align: left;
	}
	& th,
	& td {
		padding-right: 1.2rem;
		padding-left: 1.2rem;
		border-bottom: 1px solid var(--gray-3);
		border-left: 1px solid var(--gray-3);
		vertical-align: middle;
	}
	& tbody th {
		background: var(--gray-5);
	}
	& thead th {
		background: var(--gray-4);
	}
}

@media screen and (width <= 45em) {
	.table--block.table--theme1,
	.table--block.table--theme2 {
		& th {
			padding-bottom: .8rem;
		}
		& td {
			padding-top: .8rem;
			padding-left: 1.2rem;
		}
	}
}

@media (width > 60em) {
	.table--theme1,
	.table--theme2 {
		& th,
		& td {
			padding-right: 1.2rem;
			padding-left: 1.2rem;
		}
	}
}

/* -------------------------------- *\
    タブ切り替え
\* -------------------------------- */
.js-tabchange {
	& [role="tablist"] {
		list-style: none;
		display: flex;
		width: 100%;
		margin: 0;
		padding: 0;

		& li {
			flex: 1 1 0%;
			position: relative;
			margin-bottom: 0;
			padding: 0 .9rem;
		}
		& li:nth-of-type(n+2)::before {
			content: "";
			position: absolute;
			top: 1.2rem;
			bottom: 1.2rem;
			left: 0;
			border-left: 1px solid var(--gray-4);
		}
	}
	& [role="tab"] {
		display: block;
		appearance: none;
		width: 100%;
		min-width: 5em;
		padding: 1.2rem .9rem;
		border: none;
		border-bottom: 3px solid transparent;
		background: none;
		color: inherit;
		line-height: 1;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;

		&[aria-selected="true"] {
			border-bottom: 3px solid var(--color-secondary);
			font-weight: bold;
		}
	}
	& [role="tabpanel"][hidden] {
		display: none;
	}
}

@media screen and (width <= 60em) {
	.js-tabchange {
		& [role="tablist"] {
			overflow-x: auto;
			position: relative;
			left: 50%;
			width: 100vw;
			margin-left: -50vw;
			padding-right: 4.8vw;
			padding-left: 4.8vw;
		}
		& [role="tab"] {
			font-size: 1.4rem;
		}
	}
}

@media (width > 60em) {
	.js-tabchange {
		& [role="tablist"] {
			justify-content: center;

			& li {
				padding: 0 1.6rem;
			}
			& li:nth-of-type(n+2)::before {
				top: 1.6rem;
				bottom: 1.6rem;
			}
		}
		& [role="tab"] {
			padding: 2rem 1.6rem;
			font-size: 1.8rem;
		}
	}
}

/* TOPニュースリリース用 */
.news-tab__header {
	position: relative;
	display: none;

	& [role="tablist"] {
		& li {
			padding: 0;
		}
	}
}
.news-tab__rss {
	position: absolute;
	right: 0;
	top: -.75em;

	& a {
		display: block;
		padding: .2rem .4rem;
		color: inherit;
		font-weight: bold;
		line-height: 1;
		text-decoration: none;
		white-space: nowrap;
	}
	& .icon-rss {
		color: var(--gray-2);
		font-size: 1.8rem;
		vertical-align: -.05em;
	}
	& a:hover .icon-rss {
		color: var(--color-secondary);
	}
}
@media (width > 35em) {
	.news-tab__header {
		display: flex;
	}
}
@media (width > 60em) {
	.news-tab__header {
		justify-content: center;
		padding-top: 0;

		& [role="tablist"] {
			width: auto;

			& li {
				flex: 1 0 auto;
				padding: 0;
			}
			& li:nth-of-type(n+2)::before {
				top: 1.2rem;
				bottom: 1.2rem;
			}
		}
		& [role="tab"] {
			min-width: 130px;
			padding: 1.2rem 1.6rem;
			font-size: 1.6rem;
			white-space: nowrap;
		}
	}
	.news-tab__rss {
		top: 4px;
		bottom: auto;
	}
}

@media (width > 80em) {
	.news-tab__header {
		& [role="tab"] {
			width: 170px;
		}
	}
}

/* -------------------------------- *\
    Flex Grid
\* -------------------------------- */
.grid-row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -9px;
	margin-right: -9px;
}
.grid-row--reverse { flex-direction: row-reverse; }
.grid-row--start { justify-content: flex-start; }
.grid-row--center { justify-content: center; }
.grid-row--end { justify-content: flex-end; }
.grid-row--between { justify-content: space-between; }
.grid-row--around { justify-content: space-around; }
.grid-row--stretch { align-items: stretch; }
.grid-row--top { align-items: flex-start; }
.grid-row--middle { align-items: center; }
.grid-row--bottom { align-items: flex-end; }

@media (width > 35em) {
	.grid-row--reverse-sm { flex-direction: row-reverse; }
	.grid-row--start-sm { justify-content: flex-start; }
	.grid-row--center-sm { justify-content: center; }
	.grid-row--end-sm { justify-content: flex-end; }
	.grid-row--between-sm { justify-content: space-between; }
	.grid-row--around-sm { justify-content: space-around; }
	.grid-row--stretch-sm { align-items: stretch; }
	.grid-row--top-sm { align-items: flex-start; }
	.grid-row--middle-sm { align-items: center; }
	.grid-row--bottom-sm { align-items: flex-end; }
}

@media print, (width > 45em) {
	.grid-row--reverse-md { flex-direction: row-reverse; }
	.grid-row--start-md { justify-content: flex-start; }
	.grid-row--center-md { justify-content: center; }
	.grid-row--end-md { justify-content: flex-end; }
	.grid-row--between-md { justify-content: space-between; }
	.grid-row--around-md { justify-content: space-around; }
	.grid-row--stretch-md { align-items: stretch; }
	.grid-row--top-md { align-items: flex-start; }
	.grid-row--middle-md { align-items: center; }
	.grid-row--bottom-md { align-items: flex-end; }
}

@media print, (width > 60em) {
	.grid-row {
		margin-left: -16px;
		margin-right: -16px;
	}
	.grid-row--reverse-lg { flex-direction: row-reverse; }
	.grid-row--start-lg { justify-content: flex-start; }
	.grid-row--center-lg { justify-content: center; }
	.grid-row--end-lg { justify-content: flex-end; }
	.grid-row--between-lg { justify-content: space-between; }
	.grid-row--around-lg { justify-content: space-around; }
	.grid-row--stretch-lg { align-items: stretch; }
	.grid-row--top-lg { align-items: flex-start; }
	.grid-row--middle-lg { align-items: center; }
	.grid-row--bottom-lg { align-items: flex-end; }
}

[class="grid-col"],
[class*="grid-col-"] {
	padding-left: 9px;
	padding-right: 9px;
	flex-grow: 1;
	flex-basis: 0%;
	max-width: 100%;
}
.grid-col-auto {
	flex: 0 1 auto;
	max-width: none;
}
.grid-col-10 {
	flex-basis: 10%;
	max-width: 10%;
}
.grid-col-15 {
	flex-basis: 15%;
	max-width: 15%;
}
.grid-col-20 {
	flex-basis: 20%;
	max-width: 20%;
}
.grid-col-25 {
	flex-basis: 25%;
	max-width: 25%;
}
.grid-col-30 {
	flex-basis: 30%;
	max-width: 30%;
}
.grid-col-33 {
	flex-basis: 33.3333%;
	max-width: 33.3333%;
}
.grid-col-40 {
	flex-basis: 40%;
	max-width: 40%;
}
.grid-col-50 {
	flex-basis: 50%;
	max-width: 50%;
}
.grid-col-60 {
	flex-basis: 60%;
	max-width: 60%;
}
.grid-col-66 {
	flex-basis: 66.6666%;
	max-width: 66.6666%;
}
.grid-col-70 {
	flex-basis: 70%;
	max-width: 70%;
}
.grid-col-75 {
	flex-basis: 75%;
	max-width: 75%;
}
.grid-col-80 {
	flex-basis: 80%;
	max-width: 80%;
}
.grid-col-90 {
	flex-basis: 90%;
	max-width: 90%;
}
.grid-col-100 {
	flex-basis: 100%;
	max-width: 100%;
}

@media (width > 35em) {
	.grid-col-auto-sm {
		flex-basis: auto;
		max-width: none;
	}
	.grid-col-10-sm {
		flex-basis: 10%;
		max-width: 10%;
	}
	.grid-col-15-sm {
		flex-basis: 15%;
		max-width: 15%;
	}
	.grid-col-20-sm {
		flex-basis: 20%;
		max-width: 20%;
	}
	.grid-col-25-sm {
		flex-basis: 25%;
		max-width: 25%;
	}
	.grid-col-30-sm {
		flex-basis: 30%;
		max-width: 30%;
	}
	.grid-col-33-sm {
		flex-basis: 33.3333%;
		max-width: 33.3333%;
	}
	.grid-col-40-sm {
		flex-basis: 40%;
		max-width: 40%;
	}
	.grid-col-50-sm {
		flex-basis: 50%;
		max-width: 50%;
	}
	.grid-col-60-sm {
		flex-basis: 60%;
		max-width: 60%;
	}
	.grid-col-66-sm {
		flex-basis: 66.6666%;
		max-width: 66.6666%;
	}
	.grid-col-70-sm {
		flex-basis: 70%;
		max-width: 70%;
	}
	.grid-col-75-sm {
		flex-basis: 75%;
		max-width: 75%;
	}
	.grid-col-80-sm {
		flex-basis: 80%;
		max-width: 80%;
	}
	.grid-col-90-sm {
		flex-basis: 90%;
		max-width: 90%;
	}
	.grid-col-100-sm {
		flex-basis: 100%;
		max-width: 100%;
	}
}

@media print, (width > 45em) {
	.grid-col-auto-md {
		flex-basis: auto;
		max-width: none;
	}
	.grid-col-10-md {
		flex-basis: 10%;
		max-width: 10%;
	}
	.grid-col-15-md {
		flex-basis: 15%;
		max-width: 15%;
	}
	.grid-col-20-md {
		flex-basis: 20%;
		max-width: 20%;
	}
	.grid-col-25-md {
		flex-basis: 25%;
		max-width: 25%;
	}
	.grid-col-30-md {
		flex-basis: 30%;
		max-width: 30%;
	}
	.grid-col-33-md {
		flex-basis: 33.3333%;
		max-width: 33.3333%;
	}
	.grid-col-40-md {
		flex-basis: 40%;
		max-width: 40%;
	}
	.grid-col-50-md {
		flex-basis: 50%;
		max-width: 50%;
	}
	.grid-col-60-md {
		flex-basis: 60%;
		max-width: 60%;
	}
	.grid-col-66-md {
		flex-basis: 66.6666%;
		max-width: 66.6666%;
	}
	.grid-col-70-md {
		flex-basis: 70%;
		max-width: 70%;
	}
	.grid-col-75-md {
		flex-basis: 75%;
		max-width: 75%;
	}
	.grid-col-80-md {
		flex-basis: 80%;
		max-width: 80%;
	}
	.grid-col-90-md {
		flex-basis: 90%;
		max-width: 90%;
	}
	.grid-col-100-md {
		flex-basis: 100%;
		max-width: 100%;
	}
}

@media print, (width > 60em) {
	[class="grid-col"],
	[class*="grid-col-"] {
		padding-left: 16px;
		padding-right: 16px;
	}
	.grid-col-auto-lg {
		flex-basis: auto;
		max-width: none;
	}
	.grid-col-10-lg {
		flex-basis: 10%;
		max-width: 10%;
	}
	.grid-col-15-lg {
		flex-basis: 15%;
		max-width: 15%;
	}
	.grid-col-20-lg {
		flex-basis: 20%;
		max-width: 20%;
	}
	.grid-col-25-lg {
		flex-basis: 25%;
		max-width: 25%;
	}
	.grid-col-30-lg {
		flex-basis: 30%;
		max-width: 30%;
	}
	.grid-col-33-lg {
		flex-basis: 33.3333%;
		max-width: 33.3333%;
	}
	.grid-col-40-lg {
		flex-basis: 40%;
		max-width: 40%;
	}
	.grid-col-50-lg {
		flex-basis: 50%;
		max-width: 50%;
	}
	.grid-col-60-lg {
		flex-basis: 60%;
		max-width: 60%;
	}
	.grid-col-66-lg {
		flex-basis: 66.6666%;
		max-width: 66.6666%;
	}
	.grid-col-70-lg {
		flex-basis: 70%;
		max-width: 70%;
	}
	.grid-col-75-lg {
		flex-basis: 75%;
		max-width: 75%;
	}
	.grid-col-80-lg {
		flex-basis: 80%;
		max-width: 80%;
	}
	.grid-col-90-lg {
		flex-basis: 90%;
		max-width: 90%;
	}
	.grid-col-100-lg {
		flex-basis: 100%;
		max-width: 100%;
	}
}

@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?t904yt') format('truetype'),
    url('fonts/icomoon.woff?t904yt') format('woff'),
    url('fonts/icomoon.svg?t904yt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-rss:before {
  content: "\e91f";
}
.icon-globe:before {
  content: "\e91e";
}
.icon-close:before {
  content: "\e908";
}
.icon-clear:before {
  content: "\e907";
}
.icon-suggest:before {
  content: "\e91d";
}
.icon-reload:before {
  content: "\e918";
}
.icon-arrow_s:before {
  content: "\e902";
}
.icon-arrow-right_s:before {
  content: "\e923";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-arrow-left:before {
  content: "\e91a";
}
.icon-arrow-up:before {
  content: "\e920";
}
.icon-arrow-down:before {
  content: "\e921";
}
.icon-search:before {
  content: "\e919";
}
.icon-accountdata:before {
  content: "\e900";
}
.icon-info:before {
  content: "\e90f";
}
.icon-key:before {
  content: "\e910";
}
.icon-alert:before {
  content: "\e901";
}
.icon-gps:before {
  content: "\e90c";
}
.icon-help:before {
  content: "\e90d";
}
.icon-pin:before {
  content: "\e916";
}
.icon-check:before {
  content: "\e906";
}
.icon-expansion:before {
  content: "\e90b";
}
.icon-mail:before {
  content: "\e911";
}
.icon-history:before {
  content: "\e90e";
}
.icon-setting:before {
  content: "\e91b";
}
.icon-complete:before {
  content: "\e909";
}
.icon-calender:before {
  content: "\e905";
}
.icon-payment:before {
  content: "\e914";
}
.icon-store:before {
  content: "\e91c";
}
.icon-myreview:before {
  content: "\e913";
}
.icon-coupon:before {
  content: "\e90a";
}
.icon-plus:before {
  content: "\e917";
}
.icon-minus:before {
  content: "\e912";
}
.icon-blanklink:before {
  content: "\e904";
}
.icon-pdf:before {
  content: "\e915";
}
.icon-freedial:before {
  content: "\e922";
}
.icon-file-excel:before {
  content: "\eae2";
}

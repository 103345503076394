/* Base Style Setting */
html {
	font-size: 62.5%;
}
body {
	overflow-x: hidden;
	background: #fff;
	color: var(--color-text);
	font-family: var(--font-family);
	font-size: 1.6rem;
	line-height: 1.571428;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-size-adjust: 100%;
	touch-action: manipulation;
}
a {
	color: var(--color-link);
	text-decoration: none;
	&:hover img{
		opacity: .75;
	}
}
a:hover {
	text-decoration: underline;
}
a:focus {
	outline: 2px auto Highlight;
	outline: 5px auto -webkit-focus-ring-color;
}

/* Typography Base Setting */
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
}
p, dl, blockquote {
	margin-top: 0;
	margin-bottom: 1.2rem;
}
dd {
	margin-left: 0;
}
ul {
	list-style: "\30FB" outside;
	padding-left: 1.2em;
}
ol {
	list-style: decimal outside;
	padding-left: 2em;
}
ul,
ol {
	margin-top: 0;
	margin-bottom: 1.2rem;

	& > li {
		margin-bottom: .8rem;
	}
	& ul,
	& ol {
		margin-top: .6rem;
		margin-bottom: 1.2rem;
	}
}
em {
	font-style: normal;
}
rp, rt {
	line-height: 1;
	font-size: .6875em;
}
table {
	empty-cells: show;
	font-size: 1em;
}
figure {
	margin: 0;
}
picture {
	display: block;
}
img {
	height: auto;
}
iframe {
	width: 100%;
}
img, iframe, button, input, select, textarea {
	max-width: 100%;
}
fieldset {
	margin: 0;
	padding: 0;
	border: none;
}
legend {
	margin: 0;
	padding: 0;
}
input, select, textarea {
	font-family: inherit;
}
textarea {
	height: auto;
}
::placeholder {
	font-family: inherit;
}
:-ms-input-placeholder {
	font-family: inherit;
}
.no-link {
	color: inherit;
	pointer-events: none;
}

@media (prefers-reduced-motion: reduce) {
	*,
	*::before,
	*::after {
		animation-duration: .01s !important;
		animation-iteration-count: 1 !important;
		scroll-behavior: auto !important;
		transition-delay: .01s !important;
		transition-duration: .01s !important;
	}
}

/* -------------------------------- *\
    Display
\* -------------------------------- */
/* 要素の表示/非表示を制御する汎用クラス */
.display-none { display: none !important; }
.display-block { display: block !important; }
.display-inline { display: inline !important; }
.display-inline-block { display: inline-block !important; }
.display-flex { display: flex !important; }

@media (width > 35em) {
	.display-none-sm { display: none !important; }
	.display-block-sm { display: block !important; }
	.display-inline-sm { display: inline !important; }
	.display-inline-block-sm { display: inline-block !important; }
	.display-flex-sm { display: flex !important; }
}

@media (width > 45em) {
	.display-none-md { display: none !important; }
	.display-block-md { display: block !important; }
	.display-inline-md { display: inline !important; }
	.display-inline-block-md { display: inline-block !important; }
	.display-flex-md { display: flex !important; }
}

@media (width > 60em) {
	.display-none-lg { display: none !important; }
	.display-block-lg { display: block !important; }
	.display-inline-lg { display: inline !important; }
	.display-inline-block-lg { display: inline-block !important; }
	.display-flex-lg { display: flex !important; }
}

@media print {
	.print-none { display: none !important; }
	.print-block { display: block !important; }
	.print-inline { display: inline !important; }
	.print-inline-block { display: inline-block !important; }
	.print-flex { display: flex !important; }
}

/* スクリーンリーダー用 */
.sr-only-focusable {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	padding: .8rem 1.6rem;
	background: hsla(var(--color-primary-h) var(--color-primary-s) var(--color-primary-l) / 70%);
	color: #fff;
}
.visually-hidden,
.sr-only,
.sr-only-focusable:not(:focus) {
	clip: rect(0, 0, 0, 0);
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	bottom: 0;
	white-space: nowrap;
}

/* -------------------------------- *\
    9. HEADING
\* -------------------------------- */
.h1 {
	margin-bottom: 1.6rem;
	font-size: 2.4rem;
	font-weight: bold;
	line-height: 1.4;

	&[data-en]::after {
		content: attr(data-en);
		display: block;
		font-size: 1.4rem;
	}
}
.h2 {
	margin-bottom: 1.6rem;
	font-size: 2.2rem;
	font-weight: bold;
	line-height: 1.5;

	&[data-en]::after {
		content: attr(data-en);
		display: block;
		font-size: 1.4rem;
	}
}
.h3 {
	position: relative;
	margin-bottom: 1.6rem;
	padding-left: 1.2rem;
	font-size: 2rem;
	font-weight: bold;
	line-height: 1.5;

	&::before {
		content: "";
		position: absolute;
		top: .2em;
		left: 0;
		width: .5rem;
		height: 1.2em;
		border-radius: .3rem;
		background: var(--gray-4);
	}
}
.h4 {
	margin-bottom: 1.6rem;
	padding-bottom: .8rem;
	border-bottom: .2rem solid var(--gray-4);
	font-size: 1.8rem;
	font-weight: bold;
	line-height: 1.5;
}
.h4--noline {
	padding-bottom: 0;
	border-bottom: none;
}
.h5 {
	margin-bottom: 1.2rem;
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1.5;
}
.subheading {
	margin-bottom: .8rem;
	font-size: 1.4rem;
	font-weight: bold;
	line-height: 1.6;
}

@media (width > 60em) {
	.h1 {
		margin-bottom: 2.4rem;
		font-size: 4rem;
		line-height: 1.3;

		&[data-en]::after {
			font-size: 1.6rem;
		}
	}
	.h2 {
		margin-bottom: 2.4rem;
		font-size: 3rem;
		line-height: 1.3;
	}
	.h3 {
		padding-left: 1.5rem;
		font-size: 2.6rem;
		line-height: 1.4;

		&::before {
			top: .15em;
			width: .6rem;
			border-radius: .3rem;
		}
	}
	.h4 {
		font-size: 2.2rem;
	}
	.h5 {
		font-size: 1.8rem;
	}
	.subheading {
		margin-bottom: 1.2rem;
		font-size: 1.6rem;
		line-height: 1.5;
	}
}

/* -------------------------------- *\
    10. TEXT
\* -------------------------------- */
/* 10-1: リード文 */
.text-lead {
	margin-bottom: 1.6rem;
	font-size: 1.6rem;
	line-height: 1.5;
}

/* 10-3: 重要 */
/* 10-5: テキスト - 緊急/エラー */
.text-important,
.text-alert { color: var(--color-error); }
.text-alert {
	padding-left: 2.7rem;
	text-indent: -2.7rem;
}
.text-alert::before {
	content: "\e901";
	margin-right: .3rem;
	font-family: var(--iconfont);
	font-size: 2.4rem;
	vertical-align: middle;
	line-height: 1;
}

/* -------------------------------- *\
    11. TEXT LABEL
\* -------------------------------- */
.text-label {
	display: inline-block;
	min-width: 4em;
	padding: 0 .6rem;
	background: var(--gray-2);
	color: #fff;
	font-size: 1.2rem;
	font-weight: bold;
	line-height: 1.8rem;
	text-align: center;
}
.text-label--1 {
	background: #bacff0;
	color: var(--color-secondary);
}
.text-label--2 {
	background: var(--color-primary);
	color: #fff;
}
.text-label--3 {
	box-shadow: inset 0 0 0 1px var(--color-primary);
	background: #fff;
	color: var(--color-primary);
}
.text-label--4 {
	background: #e7eaee;
	color: var(--color-primary);
}
.text-label--5 {
	background: var(--gray-2);
	color: #fff;
}
.text-label--new {
	background: var(--color-secondary);
	color: #fff;
}

/* -------------------------------- *\
    15. LINKS
\* -------------------------------- */
/* 15-1: 文中リンク */
.link {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}
.link-arrow {
	position: relative;
	padding-left: 1.5rem;

	&::before {
		content: "\e903";
		position: absolute;
		top: .2em;
		left: 0;
		color: var(--color-secondary);
		font-display: block;
		font-family: var(--iconfont);
		font-size: 1.2rem;
		font-weight: normal;
		text-decoration: none;
	}
	&:hover {
		color: var(--color-secondary);
		text-decoration: underline;
	}
}

/* 15-2: テキストリンク, テキストリンク - 右 */
/* 15-3: テキストリンク - 中央 */
.link-block {
	& a {
		display: inline-block;
		padding: 1.6rem 0;
	}
}
.link-block.text-center {
	& a {
		font-size: 1.6rem;
		line-height: 1.5;
	}
}
.link-external::after {
	content: "\e904";
	padding-left: .3rem;
	color: var(--color-secondary);
	font-family: var(--iconfont);
	text-decoration: none;
	vertical-align: middle;
}

/* -------------------------------- *\
    Spaces (Vertical)
\* -------------------------------- */
/* 4, 8, 12, 16, 24, 30, 40, 50, 60, 70, 80px */

/* Margin Top */
.mt-0 { margin-top: 0 !important; }
.mt-4 { margin-top: 4px !important; }
.mt-8 { margin-top: 8px !important; }
.mt-12 { margin-top: 12px !important; }
.mt-16 { margin-top: 16px !important; }
.mt-24 { margin-top: 24px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-80 { margin-top: 80px !important; }

/* Margin Bottom */
.mb-0 { margin-bottom: 0 !important; }
.mb-4 { margin-bottom: 4px !important; }
.mb-8 { margin-bottom: 8px !important; }
.mb-12 { margin-bottom: 12px !important; }
.mb-16 { margin-bottom: 16px !important; }
.mb-24 { margin-bottom: 24px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-80 { margin-bottom: 80px !important; }

/* Padding Top */
.pt-0 { padding-top: 0 !important; }
.pt-4 { padding-top: 4px !important; }
.pt-8 { padding-top: 8px !important; }
.pt-12 { padding-top: 12px !important; }
.pt-16 { padding-top: 16px !important; }
.pt-24 { padding-top: 24px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-80 { padding-top: 80px !important; }

/* Padding Bottom */
.pb-0 { padding-bottom: 0 !important; }
.pb-4 { padding-bottom: 4px !important; }
.pb-8 { padding-bottom: 8px !important; }
.pb-12 { padding-bottom: 12px !important; }
.pb-16 { padding-bottom: 16px !important; }
.pb-24 { padding-bottom: 24px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-80 { padding-bottom: 80px !important; }

/* -------------------------------- *\
    Spaces (Horizontal)
\* -------------------------------- */
/* 3, 6, 9, 12, 16, 20, 24, 48px */
.mx-auto,
.mr-auto { margin-right: auto !important; }
.mx-auto,
.ml-auto { margin-left: auto !important; }

/* Margin Right */
.mr-0 { margin-right: 0 !important; }
.mr-3 { margin-right: 3px !important; }
.mr-6 { margin-right: 6px !important; }
.mr-9 { margin-right: 9px !important; }
.mr-12 { margin-right: 12px !important; }
.mr-16 { margin-right: 16px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-24 { margin-right: 24px !important; }
.mr-48 { margin-right: 48px !important; }

/* Margin Left */
.ml-0 { margin-left: 0 !important; }
.ml-3 { margin-left: 3px !important; }
.ml-6 { margin-left: 6px !important; }
.ml-9 { margin-left: 9px !important; }
.ml-12 { margin-left: 12px !important; }
.ml-16 { margin-left: 16px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-24 { margin-left: 24px !important; }
.ml-48 { margin-left: 48px !important; }

/* -------------------------------- *\
    Text
\* -------------------------------- */
/*
 * テキストのスタイルを指定する汎用クラス
 */

/* 文字の太さ */
.font-weight-bold {
	font-weight: bold;
}
.font-weight-normal {
	font-weight: 400;
}

/* 文字サイズ */
.font-size-l {
	font-size: 2.2rem;
}
.font-size-m {
	font-size: 1.6rem;
}
.font-size-s {
	font-size: 1.2rem;
}

@media (width > 60em) {
	.font-size-l {
		font-size: 3rem;
	}
	.font-size-m {
		font-size: 1.8rem;
	}
}

/* 文字色 */
.color-primary { color: var(--color-primary); }
.color-secondary { color: var(--color-secondary); }
.color-white { color: #fff; }
.color-normal { color: var(--color-text); }

/* 水平方向の配置 */
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-align-middle { vertical-align: middle; }
.text-align-bottom { vertical-align: bottom; }

@media (width > 35em) {
	.text-left-sm { text-align: left; }
	.text-center-sm { text-align: center; }
	.text-right-sm { text-align: right; }
}

@media (width > 45em) {
	.text-left-md { text-align: left; }
	.text-center-md { text-align: center; }
	.text-right-md { text-align: right; }
}

@media (width > 60em) {
	.text-left-lg { text-align: left; }
	.text-center-lg { text-align: center; }
	.text-right-lg { text-align: right; }
}

/* 字下げ */
.text-indent-1,
li.text-indent-1,
ul.text-indent-1 > li {
	padding-left: 1em;
	text-indent: -1em;
}
.text-indent-2,
li.text-indent-2,
ul.text-indent-2 > li {
	padding-left: 2em;
	text-indent: -2em;
}
ul[class*="text-indent-"] {
	list-style: none;
	padding-left: 0;
	text-indent: 0;
}

/* その他文字設定 */
.nowrap {
	white-space: nowrap;
}

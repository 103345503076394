/* -------------------------------- *\
    Pagination
\* -------------------------------- */
.pagination {
	display: flex;
	justify-content: center;
}
.pagination-list {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: auto;
	margin: 0;
	padding: 0;

	& > li {
		display: flex;
		align-items: center;
		margin-bottom: 0;
	}
}
.pagination-page,
.pagination-prev,
.pagination-next {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0 .4rem;
	color: var(--color-primary);
	text-decoration: none !important;

	&[disabled] {
		cursor: default;
		opacity: .3;
		pointer-events: none;
	}
}
.pagination-page {
	width: 2.25em;
	height: 2.25em;
	border-radius: 50%;

	&:hover {
		background: var(--gray-5);
		text-decoration: none;
	}
	&[aria-current] {
		background: var(--color-primary);
		color: #fff;
	}
}
.pagination-prev,
.pagination-next {
	color: var(--color-secondary);

	&:hover {
		color: var(--color-primary);
	}
}
.pagination-prev::before,
.pagination-next::before {
	content: "";
	font-family: var(--iconfont);
	font-size: 2.4rem;
	line-height: 1;
}
.pagination-prev::before {
	content: "\e91a";
}
.pagination-next::before {
	content: "\e903";
}

/* -------------------------------- *\
    ページ構成
\* -------------------------------- */
.bo-page-title {
	position: relative;
	margin-bottom: 30px;
	border-bottom: 1px solid var(--gray-4);

	& .h1 {
		margin-bottom: 16px;
	}
}

@media (width > 60em) {
	.bo-page-title {
		margin-bottom: 60px;
	}
}

/* -------------------------------- *\
    15. LINKS
\* -------------------------------- */
/* 15-7: ニュース一覧 */
.bo-news-list {
	& ul {
		list-style: none;
		margin: 0;
		padding: 0;
		border-top: 1px solid var(--gray-4);
	}
	& li {
		margin-bottom: 0;
		padding: 1.2rem 0;
		border-bottom: 1px solid var(--gray-4);
	}
}
.bo-news-list__date {
	margin-bottom: .4rem;
}
.bo-news-list__category {
	width: 11rem;

	& .text-label {
		width: 100%;
	}
}
.bo-news-list__title {
	position: relative;
	margin-top: .4rem;

	& a {
		display: block;
		color: inherit;
	}
	& a[target="_blank"]::after,
	& a[href*=".pdf"]::after {
		display: inline-block;
		width: 2rem;
		margin-left: .3rem;
		color: var(--gray-2);
		font-display: block;
		font-size: 1.6rem;
		font-family: var(--iconfont);
		font-weight: normal;
		text-align: center;
		text-decoration: none;
		line-height: 1;
	}
	& a[target="_blank"]::after {
		content: "\e904";
	}
	& a[href*=".pdf"]::after {
		content: "\e915";
	}
}
.bo-news-list--important {
	& ul {
		list-style: none;
		margin: 0;
		padding: 0;
		border-top: none !important;
	}
	& li {
		margin-bottom: 0;
		padding: .5rem 0 !important;
		border-bottom: none !important;
	}
	& .bo-news-list__title{
		padding-left: 0 !important;
	}
}

@media print, (width > 60em) {
	.bo-news-list {
		& li {
			display: table;
			width: 100%;
			padding: 2rem;
		}
	}
	.bo-news-list__date,
	.bo-news-list__category,
	.bo-news-list__title {
		display: table-cell;
		vertical-align: top;
	}
	.bo-news-list__date {
		width: calc(9em + 6.8rem);
		margin-bottom: 0;
		white-space: nowrap;
	}
	.bo-news-list__title {
		margin-top: 0;

		& a:hover {
			color: var(--color-secondary);
		}
		& a:hover::after {
			color: var(--color-primary);
		}
	}
	.has-category .bo-news-list__date {
		width: calc(9em + 4.8rem);
	}
	.has-category .bo-news-list__title {
		padding-left: 2rem;
	}
	.bo-news-list--important {
		& li {
			padding: .5rem 20px !important;
		}
	}
}

/* ニュースアーカイブ選択 */
.bo-news-list-header {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}
.bo-news-list-header__select {
	margin-left: auto;
}
.bo-news-list-header__rss {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 1px solid var(--gray-4);
	text-align: right;
}

/* -------------------------------- *\
    17. INFORMATION
\* -------------------------------- */
/* 17: お知らせ */
.bo-info-list {
	& ul {
		list-style: none;
		margin-bottom: 1.2rem;
		padding: 0;
	}
	& li {
		margin-bottom: .8rem;
	}
	& a {
		color: inherit;
	}
}
.bo-info-list__date {
	color: var(--gray-1);
	font-size: 1.2rem;
}
.bo-info-list__title {
	margin-top: .3em;
}

@media print, (width > 60em) {
	.bo-info-list {
		margin-bottom: 2rem;

		& li {
			display: table;
			width: 100%;
		}
	}
	.bo-info-list__date {
		display: table-cell;
		font-size: 1.3rem;
		vertical-align: top;
	}
	.bo-info-list__title {
		display: table-cell;
		margin-top: 0;
		padding-left: 2.4rem;
		vertical-align: top;
	}
}

/* -------------------------------- *\
    19. CONTENTS
\* -------------------------------- */
/* 19: コンテンツエリア  4カラム */
.bo-card-block {
	display: flex;
	flex-wrap: wrap;
	margin-right: -9px;
	margin-left: -9px;
}
.bo-card-block__item {
	flex: 1 0 50%;
	max-width: 50%;
	margin-bottom: 30px;
	padding: 0 9px;
}
.bo-card {
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
	padding-bottom: 1.2rem;
	page-break-inside: avoid;
}
.bo-card__thumb {
	flex-shrink: 0;

	& img {
		display: block;
		width: 100%;
	}
}
.bo-card__category {
	margin-bottom: .8rem;
	line-height: 1;
}
.bo-card__title {
	margin-bottom: 2rem;
	padding: 0 1.6rem;

	& a {
		color: inherit;
		text-decoration: none;
	}
	& a::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
.bo-card__date {
	margin-top: auto;
	padding: 0 1.6rem;
	font-size: 1.2rem;
}

@media print, (width > 45em) {
	.bo-card-block {
		margin-right: -12px;
		margin-left: -12px;
	}
	.bo-card-block__item {
		flex: 1 0 25%;
		max-width: 25%;
		margin-bottom: 40px;
		padding: 0 12px;
	}
	.bo-card {
		transition: all .3s;

		&:hover {
			box-shadow: 0 7px 12px -2px rgba(0, 0, 0, .15);
		}
	}
}

/* -------------------------------- *\
    20. CATEGORY
\* -------------------------------- */
/* 20: カテゴリー */
.bo-category-block {
	display: flex;
	flex-wrap: wrap;
	margin-right: -1.2%;
	margin-left: -1.2%;
}
.bo-category-block__item {
	flex: 1 0 50%;
	max-width: 50%;
	margin-bottom: 20px;
	padding: 0 1.2%;
}
.bo-category-item {
	position: relative;
}
.bo-category-item__thumb {
	margin-bottom: 8px;

	& img {
		display: block;
		width: 100%;
	}
}
.bo-category-item__title {
	& a {
		display: block;
		color: inherit;
		text-decoration: none;
	}
}
.bo-category-item .link-arrow {
	font-weight: bold;
}

@media (width <= 45em) {
	.bo-category-block.is-column2 .bo-category-block__item {
		flex: 1 0 100%;
		max-width: 100%;
	}
}

@media print, (width > 45em) {
	.bo-category-block {
		margin-right: -15px;
		margin-left: -15px;
	}
	.bo-category-block__item {
		flex: 1 0 33.3333%;
		max-width: 33.3333%;
		margin-bottom: 30px;
		padding: 0 15px;
	}
	.bo-category-item:hover .link-arrow {
		color: var(--color-secondary);
		text-decoration: underline;
	}
	.bo-category-block.is-column2 .bo-category-block__item {
		flex: 1 0 50%;
		max-width: 50%;
	}
	.bo-category-block.is-column4 .bo-category-block__item {
		flex: 1 0 25%;
		max-width: 25%;
		margin-bottom: 30px;
	}
}

/* -------------------------------- *\
    21. images
\* -------------------------------- */
.bo-images {
	position: relative;
}
.bo-images__overlay {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding-top: 24px;
}

@media screen and (width <= 45em) {
	.bo-images--column2 {
		& .bo-images__item:nth-of-type(n+2) {
			margin-top: 2.4rem;
		}
	}
}

@media print, (width > 45em) {
	.bo-images--column2 {
		display: flex;
		justify-content: space-between;
		margin-right: -10px;
		margin-left: -10px;

		& .bo-images__item {
			flex: 1;
			margin-top: 0 !important;
			padding: 0 10px;
		}
	}
}

.bo-images__image {
	overflow: hidden;
	position: relative;
	height: 0;

	& img {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.bo-images__image--large {
	padding-top: 100%;
}
.bo-images__image--medium {
	padding-top: 75%;
}
.bo-images__image--small {
	padding-top: 50%;
}

@supports (aspect-ratio: 1 / 1) {
	.bo-images__image,
	.bo-images__image--large,
	.bo-images__image--medium,
	.bo-images__image--small {
		height: auto;
		padding-top: 0;
	}
	.bo-images__image img {
		position: static;
		height: auto;
	}
	.bo-images__image--large img {
		aspect-ratio: 1 / 1;
	}
	.bo-images__image--medium img {
		aspect-ratio: 4 / 3;
	}
	.bo-images__image--small img {
		aspect-ratio: 2 / 1;
	}
}

@media print, (width > 45em) {
	.bo-images__image--large,
	.bo-images__image--small {
		padding-top: 61.80469%;
	}
	.bo-images__image--medium {
		padding-top: 30.2755%;
	}

	@supports (aspect-ratio: 1 / 1) {
		.bo-images__image,
		.bo-images__image--large,
		.bo-images__image--medium,
		.bo-images__image--small {
			padding-top: 0;
		}
		.bo-images__image--large img,
		.bo-images__image--small img {
			aspect-ratio: 1.618 / 1;
		}
		.bo-images__image--medium img {
			aspect-ratio: 3.303 / 1;
		}
	}
}

/* 拡大画像 */
.expansion_img-link{
	position: relative;
	display:inline-block;
}
.expansion_img-link--text{
	position: absolute;
	right: 0;
	bottom: 0;
	background-color:rgba(255,255,255,0.5);
	padding: .5em


}

/* -------------------------------- *\
    22. banner
\* -------------------------------- */
.bo-banners {
	& img {
		width: 100%;
		height: auto;
	}
}
.bo-banners__item {
	position: relative;
	margin-bottom: 20px;
	background: #fff;

	& h2 {
		position: absolute;
		top: 50%;
		left: 0%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		margin-left: 2rem;
	}
}

@media print, (width > 45em) {
	.bo-banners {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.bo-banners__item {
		max-width: calc(50% - 20px);
	}
	.bo-banners__item:first-child:last-child {
		max-width: 100%;
	}
}

@media print, (width > 60em) {
	.bo-banners__item {
		margin-bottom: 2.4rem;
	}
}

/* -------------------------------- *\
    サードパーティーバナー
\* -------------------------------- */
.bo-get-adobe-reader {
	font-size: 1.2rem;

	& a {
		display: block;
		max-width: 200px;
		margin-bottom: 1.2rem;
	}
}

@media print, (width > 45em) {
	.bo-get-adobe-reader {
		display: flex;
		align-items: center;

		& a {
			flex: 1 0 200px;
		}
		& p {
			padding-left: 24px;
		}
	}
}

/* -------------------------------- *\
    7. FOOTER
\* -------------------------------- */
.footer {
	padding-bottom: 40px;
	background: var(--gray-5);
}
.footer__copyright {
	color: var(--gray-2);
	font-size: 1.2rem;
	text-align: center;

	& span {
		display: inline-block;
	}
}

/* ページトップ */
.footer__pagetop {
	position: fixed;
	right: 20px;
	bottom: 20px;
	opacity: 0;
	transition: opacity .3s linear;

	&.is-scrolling {
		opacity: 1;
	}
}
.pagetop-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	background: rgba(0, 0, 0, .35);
	color: #fff;
	line-height: 1;
	transition: background .2s;

	&::before {
		content: "\e920";
		font-family: var(--iconfont);
		font-size: 2.4rem;
	}
	&:hover {
		background: rgba(0, 0, 0, .5);
		text-decoration: none;
	}
}

/* フッターナビ */
.fnav__upper {
	background: var(--color-primary);
	color: #fff;
}
.fnav__lower {
	padding: 40px 4.8% 30px;
	line-height: 1.5;
}
.fnav-logo {
	width: 284px;
	margin: 0 auto 24px;

	& img {
		display: block;
		width: 100%;
	}
}
.fnav-list {
	margin-bottom: 0;
}
.fnav-extra-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 0;
	text-align: center;

	& > li {
		margin-bottom: 4px;
		font-size: 1.3rem;
		line-height: 1.6;
	}
	& > li:nth-of-type(n+2) {
		padding-left: 1.2rem;
	}
	& > li:nth-last-of-type(n+2)::after {
		content: "";
		display: inline-block;
		width: 0;
		height: 1em;
		margin-left: 1.2rem;
		border-left: 1px solid var(--gray-2);
		vertical-align: middle;
	}
	& > li > a {
		display: inline-block;
		color: inherit;
		vertical-align: middle;
	}
}

@media (width <= 60em) {
	.fnav-list {
		& > li {
			margin-top: -1px;
			margin-bottom: 0;
			border-bottom: 1px solid var(--gray-3);
		}
		& > li > a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;
			padding: 1.6rem 4.8%;
			color: inherit;
			font-size: 1.6rem;
			text-decoration: none;
		}
		& > li > a::after {
			content: "\e902";
			margin-left: 1.2rem;
			font-family: var(--iconfont);
			font-size: 2.4rem;
			line-height: 1;
		}
	}
	.fnav-child-list {
		display: none;
	}
	.fnav-extra-list {
		& ul {
			display: none;
		}
	}
}

@media (width > 60em) {
	.fnav__upper {
		display: block;
		padding: 24px 0;

		& > div {
			width: 90.4%;
			max-width: 1200px;
			margin: 0 auto;
		}
	}
	.fnav-list {
		display: flex;

		& > li {
			flex: 1 0 20%;
			margin-bottom: 0;
			font-size: 1.8rem;
			font-weight: bold;
		}
		& li > a {
			display: inline-block;
			position: relative;
			padding-left: 1.6rem;
			color: inherit;
		}
		& > li > a::before {
			content: "\e903";
			position: absolute;
			top: .25em;
			left: 0;
			color: var(--gray-3);
			font-family: var(--iconfont);
			font-size: 1.2rem;
			text-decoration: none;
		}
	}
	.fnav-child-list {
		margin-top: 1rem;

		& > li {
			margin-bottom: .5rem;
			font-size: 1.6rem;
			font-weight: normal;
			line-height: 1.5715;
			
		}
		& > li > a {
			color: inherit;
			padding-left: 0;
			margin-left: 3rem;
		}
	}
	.fnav-child-list--mt {
		margin-top: 3.5rem;
	}
	.fnav-child-list--ms {
		margin-top: 2rem;
	}
	.fnav-extra-list {
		& > li {
			font-size: 1.5rem;
		}
	}
	.fnav-logo {
		width: 430px;
	}
}

/* Theme */
.owl-prev,
.owl-next {
	display: inline-block;
	margin: 5px;
	padding: 4px 7px;
	border-radius: 3px;
	background: #fff;
	color: var(--color-primary);
	font-size: 14px;
	cursor: pointer;
}
.owl-prev {
	position: absolute;
	left: 0;
	top: 50%;
}
.owl-next {
	position: absolute;
	right: 0;
	top: 50%;
}
.owl-nav .disabled {
	opacity: .5;
	cursor: default;
}

/* トップページ用 */
.owl-theme-index .owl-prev,
.owl-theme-index .owl-next {
	display: inline-block;
	overflow: hidden;
	position: absolute;
	top: 50%;
	width: 40px;
	height: 40px;
	margin-top: -30px;
	padding: 0;
	border-radius: 50%;
	background: rgba(255, 255, 255, .5) !important;
	color: var(--color-primary);
	text-align: center;
	line-height: 40px;
}
.owl-theme-index .owl-prev {
	left: 10px;
}
.owl-theme-index .owl-next {
	right: 10px;
}
.owl-theme-index .owl-prev,
.owl-theme-index .owl-next {
	display: none;
}
.owl-theme-index .owl-prev::before,
.owl-theme-index .owl-next::before {
	display: inline-block;
	width: 100%;
	font-family: var(--iconfont);
	font-size: 16px !important;
	text-align: center;
}
.owl-theme-index .owl-prev::before {
	content: "\e91a";
}
.owl-theme-index .owl-next::before {
	content: "\e903";
}

@media (width > 45em) {
	.owl-theme-index .owl-prev,
	.owl-theme-index .owl-next {
		display: inline-block;
	}
}
@media (width > 60em) {
	.owl-theme-index .owl-prev,
	.owl-theme-index .owl-next {
		width: 60px;
		height: 60px;
		margin-top: -30px;
		line-height: 60px;
	}
	.owl-theme-index .owl-prev {
		left: 30px;
	}
	.owl-theme-index .owl-next {
		right: 30px;
	}
	.owl-theme-index .owl-prev::before,
	.owl-theme-index .owl-next::before {
		font-size: 24px !important;
	}
}

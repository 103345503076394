/* -------------------------------- *\
    6. header
\* -------------------------------- */
.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 62px;
	padding: 0 4.8vw;
	border-top: 3px solid var(--color-primary);
	border-bottom: 1px solid var(--gray-4);
	background: #fff;
}
.header__title {
	position: relative;
	z-index: 2;
	width: 114px;
	margin: 0;
	padding-top: 14px;

	& img {
		display: block;
		width: 100%;
	}
}

@media (width > 60em) {
	.header {
		height: 112px;
		padding: 0 80px;
		border-top-width: 6px;
	}
	.header__title {
		position: absolute;
		top: 30px;
		width: 174px;
		padding-top: 0;
	}
}

/* -------------------------------- *\
    グローバルナビ
\* -------------------------------- */
@media (width <= 60em) {
	/* ハンバーガーメニュー */
	.gnav-toggle {
		position: fixed;
		top: 12px;
		right: calc(4.8% - 8px);
		z-index: 1000;
		width: 40px;
		height: 40px;
		padding: 0;
		border: none;
		background: transparent;
		color: #000;
		text-align: center;
		cursor: pointer;
	}
	.gnav-toggle__icon {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&,
		&::after,
		&::before {
			width: 22px;
			height: 3px;
			border-radius: 6px;
			background: var(--color-secondary);
		}
		&::after,
		&::before {
			display: block;
			position: absolute;
			left: 0;
			content: "";
		}
		&::before { top: -8px; }
		&::after { top: 8px; }
	}

	/* 開閉パネル */
	.gnav-panel {
		display: none;
		overflow-y: scroll;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1;
		width: 100%;
		max-height: calc(100vh - 62px);
		background: #fff;
	}
	.gnav-panel__inner {
		position: relative;
		padding-top: 70px;
		padding-bottom: 100px;
	}
	.nav-opened {
		& .gnav-toggle__icon {
			background: none;

			&::after,
			&::before {
				top: 0;
				background: var(--gray-2);
			}
			&::before { transform: rotate(45deg); }
			&::after { transform: rotate(-45deg); }
		}
		& .gnav-panel {
			display: block;
		}
	}
}

@media (width > 60em) {
	.gnav-toggle {
		display: none;
	}
	.gnav-panel {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		height: 106px;
	}
	.gnav__sub {
		position: absolute;
		top: 20px;
		right: 126px;
	}
}

/* メニュー項目 */
.gnav-list {
	list-style: none;
	margin: 0;
	padding: 0;
	line-height: 1.5;

	& > li {
		margin-bottom: 0;
	}
	& > li > a {
		display: flex;
		align-items: center;
		color: inherit;
		text-decoration: none;
	}
}
.gnav-list--sub {
	& > li > a {
		font-size: 1.4rem;
	}
	& .icon-globe {
		display: inline-block;
		position: relative;
		margin-right: .3rem;
		width: 2.4rem;
		height: 1em;
		vertical-align: middle;
	}
	& .icon-globe::before {
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -1.2rem;
		color: var(--color-secondary);
		font-size: 2.4rem;
	}
}

@media (width <= 60em) {
	.gnav-list {
		& > li > a {
			padding: 16px 4.8%;
			font-size: 1.6rem;
		}
	}
	.gnav-list--main {
		border-top: 1px solid var(--gray-4);
	}
	.gnav-list--sub {
		position: relative;
		z-index: 1;
		border-top: 1px solid var(--gray-4);

		& > li {
			border-bottom: 1px solid var(--gray-4);
		}
	}
	.gnav-parent-link {
		justify-content: space-between;
		position: relative;
		color: var(--color-text);
		text-decoration: none;

		&::after {
			content: "\e917";
			margin-left: 1.2rem;
			color: var(--color-secondary);
			font-family: var(--iconfont);
			font-size: 1.2rem;
			line-height: 1;
		}
		&[aria-expanded="true"]::after {
			content: "\e912";
		}
	}
}

@media (width > 60em) {
	.gnav-list--main {
		display: flex;

		& > li {
			min-width: 116px;
		}
		& > li > a {
			justify-content: center;
			position: relative;
			height: 50px;
			padding: 0 16px 4px;
			font-size: 1.8rem;
		}
		& > li > a::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 24px;
			margin-top: -12px;
			border-right: 1px solid var(--gray-4);
		}
		& > li:first-of-type > a::before {
			border-left: 1px solid var(--gray-4);
		}
		& > li > a::after {
			content: "";
			position: absolute;
			right: 16px;
			bottom: 0;
			left: auto;
			width: 0;
			height: 2px;
			background: var(--color-secondary);
			transition: all .2s cubic-bezier(.45, .05, .55, .95);
		}
		& > li > a:hover {
			color: var(--color-secondary);
		}
		& > li > a:hover::after {
			left: 16px;
			right: auto;
			width: calc(100% - 32px);
		}
		& > li.is-current > a {
			color: var(--color-secondary);
			font-weight: bold;
		}
		& > li.is-current > a::after {
			width: calc(100% - 32px);
			height: 4px !important;
			background: var(--color-secondary);
		}
	}
	.gnav-list--sub {
		display: flex;
		align-items: center;

		& > li {
			margin-left: 24px;
		}
		& > li > a {
			font-size: 1.6rem;
		}
		& > li > a:hover {
			text-decoration: underline;
		}
	}
}

/* ドロップダウン内 */
.gnav-child-list {
	list-style: none;
	margin-bottom: 1.8rem;
	padding-left: 0;

	& > li {
		position: relative;
		margin-bottom: 3rem;
	}
}
.gnav-child__heading a,
.gnav-child-list a {
	display: inline-block;
	position: relative;
	padding-left: 1.1em;
	color: inherit;

	&::before {
		content: "\e903";
		position: absolute;
		top: .2em;
		left: 0;
		color: var(--color-secondary);
		font-display: block;
		font-family: var(--iconfont);
		font-size: .85714em;
		font-weight: normal;
		text-decoration: none;
	}
}

@media (width <= 60em) {
	.js-dropdown-head span {
		pointer-events: none;
	}
	.js-dropdown-body[aria-hidden="true"] {
		display: none;
	}
	.gnav-child {
		padding: 3rem 4.8% 1px 4.8%;
		background: var(--gray-5);
	}
	.gnav-child__header {
		margin-bottom: 3rem;
	}
	.gnav-child__body {
		& ul {
			margin: 0;
		}
	}
}

@media (width > 60em) {
	.gnav-list--main {
		&::before {
			content: "";
			opacity: 0;
			position: fixed;
			top: 112px;
			bottom: 0;
			left: 0;
			z-index: -1;
			width: 100vw;
			background: rgb(0 0 0 / 25%);
			pointer-events: none;
			transition: all .3s ease;
		}
		&:hover::before {
			opacity: 1;
		}
	}
	.gnav-child {
		visibility: hidden;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 106px;
		z-index: 10;
		transform-origin: center top;
		width: 100%;
		transition: visibility 0s ease .3s;

		&::before {
			content: "";
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			transform: scaleY(.5);
			transform-origin: left top;
			width: 100%;
			height: 100%;
			background: var(--gray-5);
			transition: transform 0s .3s, opacity .2s ease .1s;
		}
	}
	.gnav-child__inner {
		display: flex;
		opacity: 0;
		justify-content: space-between;
		transform: translateX(30px);
		width: 90%;
		max-width: 1160px;
		margin: 0 auto;
		padding: 30px 0;
		transition: opacity .2s ease, transform 0s .4s;
	}
	.gnav-child__heading {
		margin-bottom: 2.4rem;
		font-size: 3rem;
		line-height: 1.3;

		& a {
			color: inherit;
		}
		& a:hover {
			color: var(--color-secondary);
		}
	}
	.gnav-list--main > li:hover {
		& .gnav-child {
			visibility: visible;
			transition: visibility 0s;

			&::before {
				opacity: 1;
				transform: scaleY(1);
				transition: transform .3s ease, opacity .1s;
			}
		}
		& .gnav-child__inner {
			opacity: 1;
			transform: translateX(0);
			transition: all .2s ease .2s;
		}
	}
	.gnav-child__header {
		flex: 1 0 35%;
		max-width: 35%;
	}
	.gnav-child__body {
		flex: 1 0 60%;
		max-width: 60%;
	}
	.gnav-child-list {
		columns: 2;
		font-size: 1.7rem;
	}
	.gnav-child-list > li {
		margin-top: .4rem;
		margin-bottom: 2.4rem;
	}
	.gnav-child__heading a,
	.gnav-child-list a {
		&:hover {
			color: var(--color-secondary);
		}
		&:hover::before {
			color: var(--color-primary);
		}
	}
	#gnavChild1 li:last-of-type {
		padding-bottom: 3em;
	}
	#gnavChild2 li:nth-of-type(3) {
		padding-bottom: 3em;
	}
}

/* 検索フィールド */
.gnav-search {
	position: relative;
}
.gnav-search__toggle,
.gnav-search__submit {
	display: block;
	width: 4rem;
	height: 4rem;
	border: none;
	background: none;
	line-height: 1;
	cursor: pointer;
}
.gnav-search__text {
	width: 100%;
	padding: .4rem 4rem .4rem .4rem;
	border-radius: .6rem;
	border: .2rem solid var(--gray-4);
	height: 4rem;
	font-size: 1.6rem;
	appearance: none;
	outline: 0;

	&:focus {
		border-color: var(--color-secondary);
	}
}
.gnav-search__submit {
	position: absolute;
	top: 0;
	right: 4.8%;
	color: var(--gray-2);

	& .icon-search {
		font-size: 2.4rem;
		line-height: 1;
	}
	&:hover {
		color: var(--color-secondary);
	}
}

@media (width <= 60em) {
	.gnav-search {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 0 4.8%;
		margin-top: 18px;
	}
	.gnav-search__toggle {
		display: none;
	}
}

@media (width > 60em) {
	.gnav-search {
		position: absolute;
		top: 10px;
		right: 80px;
		width: 42px;
	}
	.gnav-search__toggle {
		&::before {
			content: "\e919";
			color: var(--color-secondary);
			font-family: var(--iconfont);
			font-size: 2.4rem;
		}
	}
	.gnav-search__text {
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-out;
	}
	.gnav-search__submit {
		display: none;
		right: 0;
	}
	.search-expanded {
		& .gnav-search {
			visibility: visible;
			width: 170px;
			transition: all .2s ease-out;
		}
		& .gnav-search__toggle {
			display: none;
		}
		& .gnav-search__text {
			opacity: 1;
			visibility: visible;
		}
		& .gnav-search__submit {
			display: block;
		}
		& .gnav__sub {
			transform: translateX(-140px);
			transition: transform .2s ease-out;
		}
	}
}

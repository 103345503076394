/* -------------------------------- *\
    Utilities
\* -------------------------------- */

/* Overflow */
.overflow-hidden { overflow: hidden; }
.overflow-auto { overflow: auto; }

/* Float */
.float-left { float: left; }
.float-right { float: right; }

/* clearfix */
.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

/* Border */
.border-top-none { border-top: none; }
.border-bottom-none { border-top: none; }

/* Colors */
.bg-primary { background-color: var(--color-primary); }
.bg-secondary { background-color: var(--color-secondary); }
.bg-error { background-color: var(--color-error); }
.bg-gray-5 { background-color: var(--gray-5); }
.bg-gray-4 { background-color: var(--gray-4); }
.bg-gray-3 { background-color: var(--gray-3); }
.bg-gray-2 { background-color: var(--gray-2); }
.bg-gray-1 { background-color: var(--gray-1); }
.bg-dark { background-color: var(--color-text); }
.text-primary { color: var(--color-primary); }
.text-secondary { color: var(--color-secondary); }
.text-error { color: var(--color-error); }
.text-basic { color: var(--color-text); }
.text-white { color: #fff; }

/* -------------------------------- *\
    Sizes
\* -------------------------------- */
.width-10 { width: 10%; }
.width-20 { width: 20%; }
.width-25 { width: 25%; }
.width-30 { width: 30%; }
.width-33 { width: 33%; }
.width-40 { width: 40%; }
.width-50 { width: 50%; }
.width-60 { width: 60%; }
.width-66 { width: 66%; }
.width-70 { width: 70%; }
.width-75 { width: 75%; }
.width-80 { width: 80%; }
.width-90 { width: 90%; }
.width-100 { width: 100%; }
.width-auto { width: auto; }
